export const stories = [
  {//1
    title: 'Rocket club refines safety protocol in response to accidents at other universities',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/02/13/rocket-club-refines-safety-protocol-in-response-to-accidents-at-other-universities-2/',
    image: 'images/rocket.jpg',
  },
  {//2
    title: 'Virtual reality testing on rats to reveal insights into human brain',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/02/24/virtual-reality-testing-on-rats-to-reveal-insights-into-human-brain/',
    image: 'images/rat.jpg',
  },
  {//3
    title: 'New methods could make plants more resilient to climate changes, disease',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/03/31/new-methods-could-make-plants-more-resilient-to-climate-changes-disease/',
    image: 'images/geneediting.jpg',
  },
  {//4
    title: 'UCLA instructor leads study finding carcinogenic metals in popular African alcohol',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/04/10/ucla-instructor-leads-study-finding-carcinogenic-metals-in-popular-african-alcohol/',
    image: 'images/uganda.jpg',
  },
  {//5
    title: 'UCLA researchers develop wearable device that generates electricity from snow',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/04/23/ucla-researchers-develop-wearable-device-that-generates-electricity-from-snow/',
    image: 'images/snowpower.jpg',
  },
  {//6
    title: 'Ashe center offers free measles vaccines to prevent further outbreak',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/05/07/ashe-center-offers-free-measles-vaccines-to-prevent-further-outbreak/',
    image: 'images/vaccine.jpg',
  },
  {//7
    title: 'UCLA, USC researchers study role of gut microbiome in development of autism',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/05/20/ucla-usc-researchers-study-role-of-gut-microbiome-in-development-of-autism/',
    image: 'images/autism.jpg',
  },
  {//8
    title: 'Crazy cat lady cliche is misleading, according to new study by UCLA researchers',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/09/20/crazy-cat-lady-cliche-is-misleading-according-to-new-study-by-ucla-researchers/',
    image: 'images/catlady.png',
  },
  {//9
    title: 'New organic chemistry website hopes to be engaging, interactive student resource',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/10/01/new-organic-chemistry-website-hopes-to-be-engaging-interactive-student-resource/',
    image: 'images/garg.jpg',
  },
  {//10
    title: 'Luskin Center for Innovation receives $3M grant to assess California&#39s water needs',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/10/16/luskin-center-for-innovation-receives-3m-grant-to-assess-californias-water-needs/',
    image: 'images/luskin.jpg',
  },
  {//11
    title: 'School of engineering hosts a daylong event celebrating internet&#39s 50th birthday',
    author: 'Sameera Pant, Brittney Le, Bernard Mendez, Emily MacInnis, Shruti Iyer',
    url: 'https://dailybruin.com/2019/10/30/school-of-engineering-hosts-a-daylong-event-celebrating-internets-50th-birthday/',
    image: 'images/internet.jpg',
  },
  {//12
    title: 'UCLA researchers develop new method of detecting habitable planets',
    author: 'Bernard Mendez',
    url:  'https://dailybruin.com/2019/11/01/ucla-researchers-develop-new-method-of-detecting-habitable-planets/',
    image: 'images/planet.jpg',
  },
  {//13
    title: 'Students demand action, reduced fossil fuel use at LA youth climate strike',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/11/02/students-demand-action-reduced-fossil-fuel-use-at-la-youth-climate-strike/',
    image: 'images/climatestrike.jpg',
  },
  {//14
    title: 'Student group hosts Viennese delegation to discuss future of AI ethics',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/11/05/student-group-hosts-viennese-delegation-to-discuss-future-of-ai-ethics/',
    image: 'images/aiethics.jpg',
  },
  {//15
    title: 'Donald Trump Jr.\'s book promotion attended by over 400, marked by protests',
    author: 'Bernard Mendez, Emily MacInnis, Tiffany Zha, Jintak Han',
    url: 'https://dailybruin.com/2019/11/10/donald-trump-jr-s-book-promotion-attended-by-over-400-marked-by-protests/',
    image: 'images/trumpjr.jpg',
  },
  {//16
    title: 'Biden visits Downtown LA, condemns gun violence in wake of Santa Clarita shooting',
    url: 'https://dailybruin.com/2019/11/14/biden-visits-downtown-la-condemns-gun-violence-in-wake-of-santa-clarita-shooting/',
    author: 'Bernard Mendez',
    image: 'images/biden.jpg',
  },
  {//17
    title: 'Documentary screening event takes deep dive into shark conservation and advocacy',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2019/11/22/documentary-screening-event-takes-deep-dive-into-shark-conservation-and-advocacy/',
    image: 'images/shark.jpg',
    categories: ['science', 'campus', 'national'],
  },
  {//18
    title: 'UCLA-led study finds climate change may cause increase in premature births',
    author: 'Bernard Mendez, Shruti Iyer',
    url: 'https://dailybruin.com/2020/01/10/ucla-led-study-finds-climate-change-may-cause-increase-in-premature-births/',
    image: 'images/pregnancy.jpg',
  },
  {//19
    title: 'Search committee for EDI vice chancellor seeks student input in hiring process',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/01/16/search-committee-for-edi-vice-chancellor-seeks-student-input-in-hiring-process/',
    image: 'images/vicechancellor.jpg',
  },
  {//20
    title: 'Walking to Class',
    author: 'Laurel Woods, Bernard Mendez, Charlotte Huang, Justin Chai',
    url: 'https://stack.dailybruin.com/2020/01/17/walking-to-class/',
    image: 'images/walking.jpg',
    categories: ['science', 'projects'],
  },
  {//21
    title: 'UC Board of Regents contemplates raising tuition for California residents',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/01/24/uc-board-of-regents-contemplates-raising-tuition-for-california-residents/',
    image: 'images/regents.jpg',
  },
  {//22
    title: 'UC Regents recap - Jan. 21-23',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/01/25/uc-regents-recap-jan-21-23/',
    image: 'images/recap.jpg',
  },
  {//23
    title: 'Students react to death of former Los Angeles Lakers guard Kobe Bryant',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/01/26/students-react-to-death-of-former-los-angeles-lakers-guard-kobe-bryant/',
    image: 'images/kobe.jpg',
  },
  {//24
    title: 'UCLA sees drop in freshman, but increase in transfer applications',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/02/07/ucla-sees-drop-in-freshman-but-increase-in-transfer-applications/',
    image: 'images/applications.jpg',
  },
  {//25
    title: 'Generation Xchange program unites senior citizens and elementary school students',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/02/09/generation-xchange-program-unites-senior-citizens-and-elementary-school-students/',
    image: 'images/genx.jpg',
  },
  {//26
    title: 'UCLA faculty mitigate misinformation about coronavirus in campus event',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/02/11/ucla-faculty-mitigate-misinformation-about-coronavirus-in-campus-event/',
    image: 'images/corona.jpg',
  },
  {//27
    title: 'Bruin Republicans, Bruin Democrats face off at quarterly UCLA CrossFire debate',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/02/21/bruin-republicans-bruin-democrats-face-off-at-quarterly-ucla-crossfire-debate/',
    image: 'images/debate.jpg',
  },
  {//28
    title: 'Public health emergency declared statewide, 11 new COVID-19 cases confirmed',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/03/04/public-health-emergency-declared-in-la-following-confirmation-of-6-new-covid-19-cases/',
    image: 'images/breaking.png',
  },
  {//29
    title: 'UCLA graduate students vote to strike for COLA, pending approval from 10 departments',
    author: 'Marilyn Chavez-Martinez, Bernard Mendez, David Gray',
    url: 'https://dailybruin.com/2020/03/05/ucla-graduate-students-vote-to-strike-for-cola-pending-approval-from-10-departments/',
  },
  {//30
    title: 'Three students being tested for COVID-19 among increased university precaution',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/03/06/three-students-being-tested-for-covid-19-among-increased-university-precaution/',
  },
  {//31
    title: 'UC makes open-access deal with PLOS to reform academic journal publishing',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/03/08/uc-makes-open-access-deal-with-plos-to-reform-academic-journal-publishing/',
    image: 'images/plos.jpg',
  },
  {//32
    title: 'UCLA to suspend in-person classes until April 10 to limit spread of coronavirus',
    author: 'Sameera Pant, Julia Shapero, Bernard Mendez, Kate Nucci',
    url: 'https://dailybruin.com/2020/03/10/ucla-to-suspend-in-person-classes-until-april-10-to-limit-spread-of-coronavirus/',
    image: 'images/onlineclasses.jpg',
  },
  {//33
    title: 'UCLA students reassess finals week, spring break plans in light of COVID-19',
    author: 'Bernard Mendez, Celia Janes, Maanas Oruganti',
    url: 'https://dailybruin.com/2020/03/11/ucla-students-reassess-finals-week-spring-break-plans-in-light-of-covid-19/',
    image: 'images/springbreak.jpg',
  },
  {//34
    title: 'Report looks into COVID-19\'s impacts on people experiencing homelessness in US',
    author: 'Pheobe Chen, Bernard Mendez',
    url: 'https://dailybruin.com/2020/04/08/report-looks-into-covid-19s-impacts-on-people-experiencing-homelessness-in-us/',
    image: 'images/coronahomeless.jpg',
  },
  {//35
    title: 'School\'s Out, Zoom\'s In.',
    author: 'Keri Chen, Kelly Chen, Bernard Mendez, Madeline Blasingame, Radhika Ahuja, Andrew Kan',
    url: 'https://stack.dailybruin.com/2020/04/15/covid-collegecompare/',
    image: 'images/zoomin.png',
  },
  {//36
    title: 'Students sue UC and CSU systems, demand refunds amid COVID-19 campus closures',
    author: 'Marilyn Chavez-Martinez, Bernard Mendez',
    url: 'https://dailybruin.com/2020/04/28/students-sue-uc-and-csu-systems-demand-refunds-amid-covid-19-campus-closures/',
    image: 'images/lawsuit.jpg',
  },
  {//37
    title: 'The history of referendums on USAC ballots, what students are paying',
    author: 'Samantha Fredberg',
    url: 'https://dailybruin.com/2020/05/04/the-history-of-referendums-on-usac-ballots-what-students-are-paying/',
    image: 'images/usacfees.jpg',
  },
  {//38
    title: 'UCLA to hold summer session C online, considering selective in-person instruction',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/11/ucla-to-hold-summer-session-c-online-considering-selective-in-person-instruction/',
    image: 'images/summersession.jpg',
  },
  {//39
    title: 'UCLA tells faculty to prepare for remote fall quarter, official plans unannounced',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/13/ucla-tells-faculty-to-prepare-for-remote-fall-quarter-official-plans-unannounced/',
    image: 'images/fallquarter.jpg',
  },
  {//40
    title: 'UCLA cardiologist accused of sexual assault to have medical license revoked',
    author: 'Kari Lau, Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/15/ucla-cardiologist-accused-of-sexual-assault-has-medical-license-revoked/',
    image: 'images/revoked.jpg',
  },
  {//41
    title: 'Parent sentenced to pay $250,000 fine after attempt to bribe son’s way into UCLA',
    author: 'Bernard Mendez, Sameera Pant',
    url: 'https://dailybruin.com/2020/05/19/parent-sentenced-to-pay-250000-fine-after-attempt-to-bribe-sons-way-into-ucla',
    image: 'images/parent.jpg',
  },
  {//42
    title: 'UCEAP suspends all upcoming fall 2020 and yearlong study abroad programs',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/26/uceap-suspends-all-upcoming-fall-2020-and-yearlong-study-abroad-programs',
    image: 'images/uceap.png',
  },
  {//43
    title: 'First student case of COVID-19 on the Hill is confirmed',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/28/student-diagnosed-with-first-confirmed-case-of-covid-19-on-the-hill',
    image: 'images/covidhill.jpg',
  },
  {//44
    title: 'UCPD says officers in Twitter video did not harm black man with stun gun',
    author: 'Bernard Mendez, Elizabeth Sherwood',
    url: 'https://dailybruin.com/2020/05/28/ucpd-says-officers-in-twitter-video-did-not-harm-black-man-with-stun-gun',
    image: 'images/ucpdtaser.jpg',
  },
  {//45
    title: 'Protests erupt in Los Angeles in response to death of George Floyd',
    author: 'By Jintak Han, Genesis Qu, Bernard Mendez',
    url: 'https://dailybruin.com/2020/05/31/protests-erupt-in-los-angeles-in-response-to-death-of-george-floyd',
    image: 'images/floydprotests.jpg',
  },
  {//46
    title: 'USAC leadership requests immediate removal of National Guard from UCLA area',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/01/usac-leadership-requests-immediate-removal-of-national-guard-from-ucla-area',
    image: 'images/nationalguard.jpg',
  },
  {//47
    title: 'Majority of National Guard troops to leave LA as protests have become less violent',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/07/majority-of-national-guard-troops-to-leave-la-as-protests-have-become-less-violent',
    image: 'images/nationalguardexit.jpg',
  },
  {//48
    title: 'Brush fire breaks out near Getty Center Drive, no evacuation orders issued',
    author: 'Saumya Gupta, Genesis Qu, Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/10/brush-fire-breaks-out-near-getty-center-drive-no-evacuation-orders-issued',
    image: 'images/gettyfirept2.jpg',
  },
  {//49
    title: 'Supreme Court rules in favor of UC Regents to maintain DACA',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/18/supreme-court-rules-in-favor-of-uc-regents-to-maintain-daca',
    image: 'images/ucdaca.jpg',
  },
  {//50
    title: 'UCLA implements COVID-19 symptom monitoring survey for students and staff',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/19/ucla-implements-covid-19-symptom-monitoring-survey-for-students-and-staff',
    image: 'images/symptommonitoring.jpg',
  },
  {//51
    title: 'UCLA to create new Black Resource Center in major step for Black student community',
    author: 'Genesis Qu, Bernard Mendez',
    url: 'https://dailybruin.com/2020/06/25/ucla-to-create-new-black-resource-center-in-major-step-for-black-student-community',
    image: 'images/brc.jpg',
  },
  {//52
    title: 'University officials are assessing SEVP decision impacting international students',
    author: 'Saumya Gupta, Bernard Mendez',
    url: 'https://dailybruin.com/2020/07/08/university-officials-are-assessing-sevp-decision-impacting-international-students',
    image: 'images/sevp.jpg',
  },
  {//53
    title: 'Newsom restricts indoor activities statewide, tightens restrictions for 30 counties',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/07/13/newsom-restricts-indoor-activities-statewide-tightens-restrictions-for-30-counties',
    image: 'images/newsomrestrict.jpg',
  },
  {//54
    title: 'Federal government rescinds SEVP policy changes for international students',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/07/14/federal-government-rescinds-sevp-policy-changes-for-international-students',
    image: 'images/breaking.png',
  },
  {//55
    title: 'After 20 years of business in Ackerman Union, Campus Cuts is closing its doors',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/07/27/after-20-years-of-business-in-ackerman-union-campus-cuts-is-closing-its-doors',
    image: 'images/campuscutscut.jpg',
  },
  {//56
    title: 'ASUCLA faces lost revenue from pandemic, receives government loan',
    author: 'Sydney Kovach, Bernard Mendez',
    url: 'https://dailybruin.com/2020/07/28/asucla-faces-lost-revenue-from-pandemic-receives-government-loan',
    image: 'images/asuclappp.jpg',
  },
  {//57
    title: 'LA will shut off water, power of properties hosting large gatherings starting Friday',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/05/la-will-shut-off-water-power-of-properties-hosting-large-gatherings-starting-friday',
    image: 'images/wateroff.jpg',
  },
  {//58
    title: 'UC to require influenza immunization for students and staff in the fall',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/07/uc-to-require-influenza-immunization-for-students-and-staff-in-the-fall',
    image: 'images/influenza.jpg',
  },//59
  {
    title: 'Primo’s Donuts to fill Westwood vacancy after Stan’s Donuts closure',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/12/primos-donuts-to-fill-westwood-vacancy-after-stans-donuts-closure',
    image: 'images/primos.jpg',
  },
  {//60
    title: 'UC-AFT Local 1990 holds protest demanding greater job security outside regent’s home',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/17/uc-aft-local-1990-holds-protest-demanding-greater-job-security-outside-regents-home',
    image: 'images/ucaft.jpg',
  },
  {//61
    title: 'LADWP contains water main burst, limits flooding to UCLA structures',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/23/ladwp-contains-water-main-burst-limits-flooding-to-ucla-structures',
    image: 'images/flood.jpg',
  },
  {//62
    title: 'UCLA researcher charged with destroying evidence related to federal investigation',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/08/29/ucla-researcher-charged-with-destroying-evidence-related-to-federal-investigation',
    image: 'images/breaking.png',
  },
  {//63
    title: 'Judge issues preliminary injunction to bar SAT, ACT scores in UC admissions',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/01/judge-issues-preliminary-injunction-to-bar-sat-act-scores-in-uc-admissions',
    image: 'images/satact.jpg',
  },
  {//64
    title: 'UCLA named top public university by US News and World Report for fourth time in a row',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/13/ucla-named-top-public-university-by-us-news-and-world-report-for-fourth-time-in-a-row',
    image: 'images/uclatop.jpg',
  },
  {//65
    title: '33-year-old man arrested after he allegedly peered into Hill resident’s window',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/16/33-year-old-man-arrested-after-he-allegedly-peered-into-hill-residents-window',
    image: 'images/breaking.png',
  },
  {//66
    title: 'UCLA to likely not fully reopen until effective COVID-19 treatment is available',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/18/ucla-to-likely-not-fully-reopen-until-effective-covid-19-treatment-is-available',
    image: 'images/fourphase.jpg',
  },
  {//67
    title: 'Magnitude 4.5 earthquake strikes approximately 20 miles from UCLA campus',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/19/magnitude-4-6-earthquake-strikes-approximately-20-miles-from-ucla-campus',
    image: 'images/breaking.png',
  },
  {//68
    title: 'State audit finds 64 students inappropriately admitted to UC between 2013 and 2019',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/22/state-audit-finds-64-students-inappropriately-admitted-to-uc-between-2013-and-2019',
    image: 'images/stateaudit.jpg',
  },
  {//69
    title: 'Gov. Newsom signs AB 3092, allowing Heaps survivors more time to file civil charges',
    author: 'Genesis Qu, Bernard Mendez',
    url: 'https://dailybruin.com/2020/09/30/gov-newsom-signs-ab-3092-allowing-heaps-survivors-more-time-to-file-civil-charges',
    image: 'images/heapsab.jpg',
  },
  {//70
    title: 'Protesters march down Westwood Boulevard, call for removal of police from campuses',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/01/protesters-march-down-westwood-boulevard-call-for-removal-of-police-from-campuses',
    image: 'images/copsoffcampus.jpg',
  },
  {//71
    title: 'At least three cases of COVID-19 reported in students living on the Hill',
    author: 'Saumya Gupta, Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/09/at-least-three-cases-of-covid-19-reported-in-students-living-on-the-hill',
    image: 'images/threecovidhill.jpg',
  },
  {//72
    title: 'UCLA to offer free COVID-19 tests to all students at Ashe Center',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/09/ucla-to-offer-free-covid-19-tests-to-all-students-at-ashe-center',
    image: 'images/freetest.jpg',
  },
  {//73
    title: 'UCLA extends work from home period for faculty, staff through winter quarter',
    author: 'Genesis Qu, Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/09/ucla-extends-work-from-home-period-for-faculty-staff-through-winter-quarter',
    image: 'images/winterwfh.jpg',
  },
  {//74
    title: 'Student dies after falling from Westwood apartment building',
    author: 'Ellie Sherwood, Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/24/student-dies-after-falling-from-westwood-apartment-building',
    image: 'images/midvale.jpg',
  },
  {//75
    title: 'UCLA announces winter quarter will continue with remote instruction',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/10/26/ucla-announces-winter-quarter-will-continue-with-remote-instruction',
    image: 'images/winterquarter.jpg',
  },
  {//76
    title: 'Activists gather at Jackie Robinson Stadium to protest UCPD, LAPD',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/11/01/activists-gather-at-jackie-robinson-stadium-to-protest-ucpd-lapd',
    image: 'images/jrstadium.jpg',
  },
  {//77
    title: 'Gallery: Activists hold remembrance event at Jackie Robinson Stadium',
    author: 'Jason Zhu, Bernard Mendez, Anika Chakrabarti',
    url: 'https://dailybruin.com/2020/11/01/gallery-activists-hold-remembrance-event-at-jackie-robinson-stadium',
    image: 'images/galleryjrstadium.jpg',
  },
  {//78
    title: '2020 Election Results in California and Los Angeles County',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/11/03/2020-election-results-in-california-and-los-angeles-county',
    image: 'images/electionresults.jpg',
  },
  {//79
    title: 'Proposition 16 fails in California, ending move to reinstate affirmative action',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/11/04/proposition-16-fails-in-california-ending-move-to-reinstate-affirmative-action',
    image: 'images/prop16rejected.jpg',
  },
  {//80
    title: 'UCLA student arrested for alleged participation in US Capitol riots',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2021/02/17/ucla-student-arrested-for-alleged-participation-in-us-capitol-riots',
    image: 'images/secorarrest.jpg',
  },
  {//81
    title: 'Christian Secor to appear in court, argue for release from federal custody Wednesday',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2021/03/19/christian-secor-to-appear-in-court-argue-for-release-from-federal-custody-wednesday',
    image: 'images/secorcourt.png',
  },
  {//82
    title: 'UCLA student allegedly involved in Capitol riots to be released from custody',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2021/03/24/ucla-student-allegedly-involved-in-capitol-riots-to-be-released-from-custody',
    image: 'images/secorrelease.png',
  },
  {//83
    title: 'Examining the rise of Christian Secor and the ‘alt-right’ movement at UCLA',
    author: 'Christine Tran, Julia Shapero',
    url: 'https://dailybruin.com/2021/04/26/examining-the-rise-of-christian-secor-and-the-alt-right-movement-at-ucla',
    image: 'images/secorenterprise.png',
  },
  {//84
    title: 'Evaluating the Decade\'s Demographic Trends Among Faculty at UCLA',
    author: 'Bernard Mendez, Annie Zhang, Charlotte Huang, Jeanette Lin, Juan C. Rios',
    url: 'https://stack.dailybruin.com/2020/05/18/professor-demographics/',
    image: 'images/profdem.jpg',
  },
  {//85
    title: 'California to expand COVID-19 vaccine eligibility to all adults starting April 15',
    author: 'Bernard Mendez, Saumya Gupta',
    url: 'https://dailybruin.com/2021/03/25/california-to-expand-covid-19-vaccine-eligibility-to-all-adults-starting-april-15',
    image: 'images/vaccineexpansion.jpg',
  },
  {//86
    url: 'https://dailybruin.com/2021/04/13/daily-bruin-receives-15-honors-in-regional-collegiate-journalism-competition',
    author: 'Hyeyoon (Alyssa) Choi',
    title: 'Daily Bruin receives 15 honors in regional collegiate journalism competition',
    image: 'images/dbmoe.jpg',
  },
  {//87
    title: 'Southern California to enter stay-at-home order after ICU capacity falls below 15%',
    author: 'Bernard Mendez',
    url: 'https://dailybruin.com/2020/12/05/southern-california-to-enter-stay-at-home-order-after-icu-capacity-falls-below-15',
    image: 'images/icu.jpg',
  },
  {//88
    title: 'LA County to reopen indoor dining, gyms on Monday after moving into red tier',
    author: 'Bernard Mendez, Hyeyoon (Alyssa) Choi',
    url: 'https://dailybruin.com/2021/03/12/la-county-to-reopen-indoor-dining-gyms-on-sunday-after-moving-into-red-tier',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },

];

/*
  {
    title: '',
    author: 'Bernard Mendez',
    url: '',
    image: 'images/',
  },
 */
